import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import ContentItem from '../components/ContentItem'
import Layout from '../components/layout'
import SEO from '../components/seo'

const IndexPage = ({ children }) => (
  <Layout>
    <SEO title="Archive" />
    <StaticQuery
      query={graphql`
      query ArenaQuery {
          arenaChannel(slug: { eq: "heikkilotvonendesign" }) {
            title
            metadata {
              description
            }
            children {
              __typename
              ... on ArenaBlock {
                title
                content_html
                description_html
                image {
                  childImageSharp {
                    fluid(maxWidth: 1204) {
                      ...GatsbyImageSharpFluid_noBase64
                    }
                  }
                  url
                  extension
                  publicURL
                }
              }
            }
          }
        }
      `}
      render={data => (
        <section style={{ padding: '2rem 0' }}>
          <div>
            {data.arenaChannel.children.slice(0).reverse().map((item, index) => (
              <ContentItem key={index} item={item} />
            ))}
          </div>
        </section>
      )}
    />
  </Layout>
)

export default IndexPage
