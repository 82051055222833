import React from 'react'
import PropTypes from 'prop-types'
import Img from 'gatsby-image'

const ArenaBlock = ({
  item: { image, content_html, title, description_html, source },
}) => {
  return (
    <article style={{ paddingBottom: '1em' }}>
      {image && (
        <div style={{ marginBottom: '0.5rem' }}>
          <a href={image.url} target="_blank" rel="noopener noreferrer">
            {image && image.extension === "gif"
              ? <img src={image.publicURL} alt={title} />
              : <Img fluid={image.childImageSharp.fluid} alt={title} />
            }
          </a>
        </div>
      )}
      {content_html && (
        <div
          className="htmlcontent"
          dangerouslySetInnerHTML={{ __html: content_html }}
        />
      )}
      {title && (
        <span>{title + " "}</span>
      )}
      {description_html && (
        <>
          <span>(</span><div className="description" dangerouslySetInnerHTML={{ __html: description_html }} /><span>)</span>
        </>
      )}
      {source && (
        <a href={source.url} target="_blank" rel="noopener noreferrer">
          Source: {source.title}
        </a>
      )}
    </article>
  )
}

const ContentItem = ({ item }) => {
  if (item.__typename === 'ArenaBlock') {
    return <ArenaBlock item={item} />
  }
  return null
}

ContentItem.propTypes = {
  item: PropTypes.object.isRequired,
}

export default ContentItem
